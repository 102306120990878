import { AnimatePresence, motion } from 'framer-motion';
import React, { useState } from 'react';

import { Divider } from '@apps/web/components/atoms/divider/Divider';
import { useClosePopoverOnScroll } from '@apps/web/components/organisms/app-layout/header/hooks';
import { MenuItemWithCategorizedPages } from '@apps/web/components/organisms/app-layout/header/types';
import { DEFAULT_EASE } from '@apps/web/constants';
import { tailwindConfig } from '@canify/theme';
import { Link } from '@canify/ui-link';

type HeaderMenuItemProps = {
	menuItem: MenuItemWithCategorizedPages;
	underlineColor: string;
};

export const HeaderMenuItem = ({ menuItem, underlineColor }: HeaderMenuItemProps) => {
	const [isOpen, setIsOpen] = useState(false);

	useClosePopoverOnScroll(isOpen, setIsOpen);

	const container = {
		hidden: { opacity: 0, y: 'calc(100% + 16px)', visiblity: 'hidden' },
		show: { opacity: 1, y: '100%' },
	};

	const popoverMenu = (visible: boolean) => (
		<motion.div
			variants={container}
			initial="hidden"
			animate={visible ? 'show' : 'hidden'}
			transition={{ duration: 0.2, ease: DEFAULT_EASE }}
			className={[
				'absolute left-0 bottom-0 pt-1 shadow-lg w-80',
				!visible ? 'pointer-events-none' : null,
			].join(' ')}
		>
			<div className="bg-brand-blue px-6 py-2">
				{menuItem.categorizedPages?.map(([category, pages], index) => (
					<React.Fragment key={category}>
						{index !== 0 && <Divider backgroundColor={tailwindConfig.theme.colors['blue-tint'][75]} />}
						<ul>
							{pages.map((page, index) => (
								<li className="text-white my-4 body-small hover:opacity-50" key={index}>
									<Link className="inline-block" to={page.slug} title={page.title}>
										{page.title}
									</Link>
								</li>
							))}
						</ul>
					</React.Fragment>
				)) ?? <></>}
			</div>
		</motion.div>
	);

	const underline = (
		<motion.div
			initial={{ width: '0%' }}
			animate={{ width: '100%' }}
			style={{ height: '2px', backgroundColor: underlineColor }}
			transition={{ duration: 0.2, ease: DEFAULT_EASE }}
			className="absolute bottom-0"
		/>
	);

	return (
		<span
			className="relative py-3"
			onMouseEnter={() => setIsOpen(true)}
			onMouseLeave={() => setIsOpen(false)}
		>
			<Link to={menuItem.slug} title={menuItem.title} className="body-small inline-block py-3">
				{menuItem.title}
			</Link>

			<AnimatePresence>{isOpen && underline}</AnimatePresence>

			{menuItem.categorizedPages?.length ? <AnimatePresence>{popoverMenu(isOpen)}</AnimatePresence> : <></>}
		</span>
	);
};
