import { NavigationItemPage } from '@apps/web/lib/sanity/types';

export type CategorisedPage = [category: string, pages: NavigationItemPage[]];

export function categorizePages(navigationItemPages: NavigationItemPage[]): CategorisedPage[] {
	const categorisedPages = navigationItemPages?.reduce(
		(acc: { [category: string]: NavigationItemPage[] }, page) => {
			const category = page.transportPageInfo.category.toLowerCase();
			if (acc[category]) {
				acc[category].push(page);
			} else {
				acc[category] = [page];
			}

			return acc;
		},
		{} as { [category: string]: NavigationItemPage[] },
	);

	const categorisedPagesSorted = Object.entries(categorisedPages ?? {}).sort(([a], [b]) => a.localeCompare(b));

	return categorisedPagesSorted;
}
