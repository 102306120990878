import { motion, SVGMotionProps } from 'framer-motion';
import React from 'react';

import { DEFAULT_EASE } from '@apps/web/constants';

export type BurgerMenuButtonProps = {
	className?: string;
	onClick: () => void;
	isOpen: boolean;
	strokeColor: string;
};

export const BurgerMenuButton = ({ className, onClick, isOpen, strokeColor }: BurgerMenuButtonProps) => (
	<button className={`flex items-center justify-center h-11 w-11 ${className}`} onClick={onClick}>
		<motion.svg
			initial={false}
			animate={isOpen ? 'open' : 'closed'}
			width="20"
			height="20"
			viewBox="0 0 20 20"
		>
			<Path
				strokeWidth={2}
				stroke={strokeColor}
				variants={{
					closed: { d: 'M 2 2.5 L 20 2.5' },
					open: { d: 'M 3 16.5 L 17 2.5' },
				}}
			/>
			<Path
				strokeWidth={2}
				stroke={strokeColor}
				d="M 2 9.423 L 20 9.423"
				variants={{
					closed: { opacity: 1 },
					open: { opacity: 0 },
				}}
				transition={{ duration: 0.1 }}
			/>
			<Path
				strokeWidth={2}
				stroke={strokeColor}
				variants={{
					closed: { d: 'M 2 16.346 L 20 16.346' },
					open: { d: 'M 3 2.5 L 17 16.346' },
				}}
			/>
		</motion.svg>
	</button>
);

function Path(props: SVGMotionProps<SVGPathElement>) {
	return (
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		<motion.path
			fill="transparent"
			strokeWidth="3"
			strokeLinecap="round"
			transition={{ duration: 0.2, ease: DEFAULT_EASE }}
			{...props}
		/>
	);
}
