import React from 'react';

interface CanifyLogoProps {
	className?: string;
	fill: string;
	width?: number;
	height?: number;
}

export const CanifyLogo = ({ className, fill, width, height }: CanifyLogoProps): JSX.Element => {
	return (
		<svg
			width={width}
			height={height}
			className={className}
			viewBox="0 0 180 29"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_1468:35668)">
				<path
					d="M15.0669 17.3196C14.1674 17.9824 13.1022 18.3831 11.9893 18.4774C10.8764 18.5718 9.75907 18.3561 8.76099 17.8542C6.73667 16.8469 5.57566 14.9037 5.57566 12.5223C5.54717 11.4702 5.811 10.4308 6.33777 9.51991C6.86454 8.60902 7.63356 7.86241 8.55918 7.36324C9.53348 6.83525 10.6305 6.57605 11.7378 6.61218C12.8451 6.64831 13.9229 6.97848 14.8608 7.56886L15.2189 7.80111L18.4885 4.0194L18.0787 3.70148C16.1035 2.17125 13.6788 1.33708 11.1812 1.32862C9.38545 1.32761 7.61583 1.75962 6.0221 2.58811C4.42837 3.4166 3.05742 4.61719 2.02523 6.08831C0.993036 7.55943 0.329978 9.25779 0.092147 11.0397C-0.145684 12.8216 0.0487101 14.6347 0.658883 16.3255C1.26906 18.0163 2.27705 19.5351 3.5976 20.7534C4.91815 21.9717 6.51239 22.8537 8.24545 23.3247C9.97851 23.7957 11.7994 23.8419 13.5541 23.4594C15.3088 23.0769 16.9456 22.2769 18.3261 21.1271L18.7153 20.8053L15.4317 17.0428L15.0669 17.3196Z"
					fill={fill}
				/>
				<path
					d="M30.7724 10.4695C29.6968 9.74076 28.4445 9.31661 27.1479 9.2419C25.8513 9.16719 24.5586 9.44471 23.4066 10.0451C22.2546 10.6455 21.2861 11.5464 20.6036 12.6526C19.921 13.7587 19.5498 15.029 19.5292 16.329C19.5086 17.629 19.8394 18.9105 20.4866 20.0377C21.1337 21.165 22.0732 22.0962 23.2056 22.7329C24.338 23.3695 25.6212 23.6879 26.9196 23.6544C28.2179 23.6209 29.483 23.2367 30.5812 22.5425V23.3031H34.6844V9.61338H30.9811L30.7724 10.4695ZM29.1566 18.5511C28.9032 18.8201 28.5975 19.0343 28.2583 19.1805C27.9191 19.3267 27.5537 19.4019 27.1844 19.4015C26.8128 19.4011 26.445 19.3265 26.1026 19.1818C25.7603 19.0372 25.4502 18.8256 25.1907 18.5593C24.652 17.9909 24.3504 17.2381 24.3476 16.4546C24.3448 15.6711 24.6409 14.916 25.1755 14.3438C25.4358 14.0733 25.7478 13.858 26.0929 13.7105C26.438 13.5631 26.8092 13.4866 27.1844 13.4855C27.5554 13.4855 27.9226 13.5616 28.2631 13.7092C28.6036 13.8568 28.9103 14.0728 29.1641 14.3438C29.4337 14.6217 29.6457 14.9504 29.7877 15.3108C29.9298 15.6711 29.9992 16.0561 29.9919 16.4435C30.0004 17.2285 29.7004 17.9854 29.1566 18.5511Z"
					fill={fill}
				/>
				<path
					d="M45.5455 9.25977C44.2922 9.28887 43.0797 9.71185 42.0798 10.4688L41.6301 9.6137H37.8389V23.3034H42.5318V16.5028C42.5318 14.8717 43.6856 13.5446 45.1035 13.5446C46.5219 13.5446 47.6757 14.8717 47.6757 16.5028V23.3034H52.2497V16.5028C52.2498 12.5091 49.2424 9.25977 45.5455 9.25977Z"
					fill={fill}
				/>
				<path
					d="M83.4251 9.61352L79.9455 16.2203L76.0585 9.61352H69.0904V7.55919C69.0904 6.44831 69.2577 4.61524 70.8083 4.61524C71.2352 4.65225 71.6357 4.83733 71.9408 5.13852C72.0642 5.24504 72.2791 5.48985 72.2791 5.48985L75.5609 2.59232L75.1898 2.13594C73.8591 0.66863 72.5385 0 70.8622 0C68.7625 0 67.43 0.701409 66.2995 2.09212C65.2062 3.43667 64.5746 5.36846 64.5746 7.71676V9.6135H62.2676V14.0457H64.5746V23.3033H69.0901V14.0457H73.6086L77.6667 20.5515L73.3264 29H78.5176L88.653 9.61352H83.4251Z"
					fill={fill}
				/>
				<path d="M59.8802 9.61377H55.0996V23.3035H59.8802V9.61377Z" fill={fill} />
				<path
					d="M55.4402 1.83788C55.1697 2.10369 54.9548 2.42081 54.8082 2.7707C54.6615 3.12059 54.5859 3.49623 54.5859 3.87568C54.5859 4.25513 54.6615 4.63078 54.8082 4.98067C54.9548 5.33056 55.1697 5.64768 55.4402 5.91349C55.9889 6.44798 56.7243 6.74704 57.4899 6.74704C58.2555 6.74704 58.9909 6.44798 59.5396 5.91349C59.8101 5.64767 60.025 5.33056 60.1716 4.98067C60.3183 4.63078 60.3938 4.25513 60.3938 3.87568C60.3938 3.49623 60.3183 3.12059 60.1716 2.7707C60.025 2.42081 59.8101 2.10369 59.5396 1.83788C59.2713 1.56697 58.952 1.35194 58.6002 1.2052C58.2484 1.05846 57.871 0.98291 57.4899 0.98291C57.1088 0.98291 56.7314 1.05846 56.3796 1.2052C56.0278 1.35194 55.7085 1.56697 55.4402 1.83788Z"
					fill={fill}
				/>
				<path
					d="M109.089 17.4217C108.189 18.0844 107.124 18.4851 106.011 18.5795C104.898 18.6738 103.781 18.4581 102.783 17.9563C100.759 16.949 99.5977 15.0057 99.5977 12.6243C99.5692 11.5722 99.833 10.5328 100.36 9.62188C100.887 8.71099 101.656 7.96438 102.581 7.46521C103.556 6.93722 104.653 6.67802 105.76 6.71415C106.867 6.75028 107.945 7.08045 108.883 7.67083L109.241 7.90308L112.511 4.12137L112.101 3.80345C110.126 2.27324 107.701 1.43909 105.203 1.43067C103.407 1.42966 101.638 1.86167 100.044 2.69017C98.4503 3.51866 97.0794 4.71924 96.0472 6.19036C95.015 7.66148 94.352 9.35984 94.1141 11.1418C93.8763 12.9237 94.0707 14.7367 94.6809 16.4275C95.291 18.1183 96.299 19.6371 97.6196 20.8554C98.9401 22.0737 100.534 22.9557 102.267 23.4267C104 23.8977 105.821 23.9439 107.576 23.5614C109.331 23.1789 110.968 22.3789 112.348 21.2292L112.737 20.9074L109.454 17.1448L109.089 17.4217Z"
					fill={fill}
				/>
				<path d="M119.461 0.254395H114.681V23.5285H119.461V0.254395Z" fill={fill} />
				<path d="M127.051 9.83838H122.271V23.5281H127.051V9.83838Z" fill={fill} />
				<path
					d="M122.611 2.06297C122.341 2.32879 122.126 2.64591 121.979 2.9958C121.832 3.34569 121.757 3.72133 121.757 4.10078C121.757 4.48023 121.832 4.85588 121.979 5.20576C122.126 5.55565 122.341 5.87277 122.611 6.13859C123.16 6.67308 123.895 6.97214 124.661 6.97214C125.426 6.97214 126.162 6.67308 126.711 6.13859C126.981 5.87277 127.196 5.55566 127.343 5.20577C127.489 4.85588 127.565 4.48023 127.565 4.10078C127.565 3.72133 127.489 3.34569 127.343 2.9958C127.196 2.64591 126.981 2.32879 126.711 2.06297C126.442 1.79207 126.123 1.57704 125.771 1.4303C125.419 1.28356 125.042 1.20801 124.661 1.20801C124.28 1.20801 123.902 1.28356 123.551 1.4303C123.199 1.57704 122.879 1.79207 122.611 2.06297Z"
					fill={fill}
				/>
				<path
					d="M137.603 9.48486C136.35 9.51397 135.137 9.93695 134.137 10.6939L133.688 9.8388H129.896V23.5284H134.589V16.7279C134.589 15.0968 135.743 13.7697 137.161 13.7697C138.58 13.7697 139.733 15.0968 139.733 16.7279V23.5284H144.307V16.7279C144.307 12.7341 141.3 9.48486 137.603 9.48486Z"
					fill={fill}
				/>
				<path d="M151.91 9.83838H147.129V23.5281H151.91V9.83838Z" fill={fill} />
				<path
					d="M147.469 2.06297C147.199 2.32879 146.984 2.64591 146.837 2.9958C146.691 3.34569 146.615 3.72133 146.615 4.10078C146.615 4.48023 146.691 4.85588 146.837 5.20577C146.984 5.55566 147.199 5.87277 147.469 6.13859C148.018 6.67308 148.754 6.97214 149.519 6.97214C150.285 6.97214 151.02 6.67308 151.569 6.13859C151.839 5.87277 152.054 5.55565 152.201 5.20576C152.348 4.85588 152.423 4.48023 152.423 4.10078C152.423 3.72133 152.348 3.34569 152.201 2.9958C152.054 2.64591 151.839 2.32879 151.569 2.06297C151.301 1.79207 150.981 1.57704 150.629 1.4303C150.278 1.28356 149.9 1.20801 149.519 1.20801C149.138 1.20801 148.761 1.28356 148.409 1.4303C148.057 1.57704 147.738 1.79207 147.469 2.06297Z"
					fill={fill}
				/>
				<path
					d="M163.564 18.0594L162.748 18.6787C162.362 18.9615 161.905 19.1318 161.428 19.1708C160.951 19.2099 160.472 19.1161 160.045 18.8999C159.623 18.6931 159.271 18.3673 159.032 17.9625C158.793 17.5577 158.678 17.0917 158.7 16.622C158.683 16.1749 158.792 15.7321 159.014 15.3441C159.237 14.9561 159.564 14.6386 159.958 14.4281C160.379 14.1992 160.853 14.0864 161.331 14.1011C161.81 14.1158 162.276 14.2575 162.681 14.5118L163.483 15.0319L166.366 11.698L165.448 10.9872C164.188 10.011 162.641 9.47909 161.048 9.47412C159.901 9.47356 158.771 9.74944 157.754 10.2784C156.736 10.8075 155.861 11.574 155.202 12.5133C154.543 13.4526 154.119 14.537 153.967 15.6747C153.815 16.8124 153.939 17.9701 154.329 19.0497C154.718 20.1294 155.362 21.0992 156.205 21.8773C157.048 22.6553 158.065 23.2187 159.172 23.5197C160.278 23.8207 161.441 23.8506 162.561 23.6067C163.682 23.3628 164.727 22.8524 165.609 22.1185L166.479 21.3989L163.564 18.0594Z"
					fill={fill}
				/>
				<path
					d="M169.483 18.8437C170.528 19.3944 171.658 19.7655 172.825 19.9413C173.34 20.0213 173.862 20.0398 174.381 19.9963C174.556 19.9712 174.723 19.9079 174.871 19.8109C174.939 19.7682 174.995 19.7082 175.033 19.6369C175.071 19.5657 175.089 19.4857 175.086 19.4051C175.083 19.3245 175.059 19.2461 175.016 19.178C174.973 19.1098 174.912 19.0542 174.841 19.0169C174.57 18.8519 174.281 18.7207 173.978 18.6263C173.153 18.3599 172.305 18.1585 171.493 17.8581C170.37 17.4426 169.385 16.808 168.76 15.7413C167.721 13.9654 168.351 11.6879 170.199 10.5126C171.43 9.7298 172.806 9.48123 174.236 9.4849C176.079 9.48961 177.775 10.0519 179.384 10.9141C179.479 10.9675 179.479 10.9748 179.467 11.0006L177.66 14.3684C177.417 14.2551 177.193 14.1426 176.963 14.0455C175.943 13.5666 174.825 13.3333 173.699 13.364C173.53 13.3724 173.364 13.4095 173.207 13.4737C173.114 13.4999 173.03 13.5539 172.968 13.6285C172.906 13.703 172.867 13.7947 172.858 13.8915C172.849 13.9883 172.869 14.0856 172.916 14.1707C172.963 14.2558 173.035 14.3246 173.122 14.3681C173.425 14.5404 173.747 14.676 174.082 14.7721C174.98 15.0519 175.893 15.2873 176.787 15.5808C177.456 15.7925 178.073 16.1428 178.598 16.6089C180.262 18.1123 180.188 20.9529 178.439 22.4783C177.396 23.3883 176.134 23.7573 174.788 23.8504C172.469 24.021 170.152 23.4916 168.137 22.3306C168.054 22.2837 167.973 22.2335 167.894 22.1806C167.822 22.1329 167.807 22.1358 167.825 22.0957L169.483 18.8437Z"
					fill={fill}
				/>
			</g>
			<defs>
				<clipPath id="clip0_1468:35668">
					<rect width="179.8" height="29" fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};
