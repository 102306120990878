import queryString from 'query-string';

import { prune } from '@noaignite-dk/utils-helpers-common';

import {
	ONBOARDING_MEDICAL_DOCUMENT_START_SCREEN,
	ONBOARDING_MEDICAL_INFO_START_SCREEN,
	ONBOARDING_QUERY_FORCE_SECTION_ID,
} from '../components/templates/onboardingPage/constants';

export const ROUTES = {
	HOME: '/',
	HOME_EN: '/en',
	HOME_DE: '/de',
	ONBOARDING: '/onboarding',
	ONBOARDING_START_UPLOAD_DOCUMENT: `/onboarding?screen=${ONBOARDING_MEDICAL_DOCUMENT_START_SCREEN}&${ONBOARDING_QUERY_FORCE_SECTION_ID}=2`,
	ONBOARDING_START_MEDICAL_INFO: `/onboarding?screen=${ONBOARDING_MEDICAL_INFO_START_SCREEN}`,
	PATIENT: '/patient-universe',
	PATIENT_BOOKING: '/patient-universe/?tab=booking',
	PATIENT_PROFILE: '/patient-universe/?tab=profile',
	PATIENT_CHECKINS: '/patient-universe/?tab=checkin',
	PATIENT_GUIDES: '/patient-universe/?tab=guides',
	PATIENT_WEEKLY_CHECKIN: '/patient-universe/check-in',
	SUPPORT: '/support-universe',
	SUPPORT_PATIENT: '/support-universe?tab=patient',
	SUPPORT_CALENDAR: '/support-universe?tab=calendar',
	SUPPORT_TICKETS: '/support-universe?tab=tickets',
	404: '/404',
	PREPARATION_FOR_THE_CONSULTATION: '/preparation-for-the-consultation',
	PREPARATION_FOR_THE_CONSULTATION_DE: '/de/vorbereitung-auf-die-konsultation',
};

export const getRoute = (route: string, query?: Record<string, string | number | boolean>) => {
	if (query) {
		const formattedQueries = queryString.stringify(prune(query));

		if (route.includes('?')) {
			return `${route}&${formattedQueries}`;
		}

		return `${route}?${formattedQueries}`;
	}

	return route;
};
