import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';

import {
	useCloseMenuWhenDesktopScreen,
	useDisableScrollWhenMenuOpen,
} from '@apps/web/components/organisms/app-layout/header/hooks';
import {
	ColorScheme,
	MenuItemWithCategorizedPages,
} from '@apps/web/components/organisms/app-layout/header/types';
import { HEADER_HEIGHT } from '@apps/web/constants';
import { tailwindConfig } from '@canify/theme';
import { BurgerMenuButton } from '@canify/ui-burger-menu-button';
import { Link } from '@canify/ui-link';

type BurgerMenuProps = {
	menuItems: MenuItemWithCategorizedPages[];
	colorScheme: ColorScheme;
	setDrawerVisiblity: (visiblity: boolean) => void;
};

export const BurgerMenu = ({ menuItems, colorScheme, setDrawerVisiblity }: BurgerMenuProps) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggleMenu = () => setIsOpen((prev) => !prev);

	useEffect(() => {
		setDrawerVisiblity(isOpen);
		return () => setDrawerVisiblity(false);
	}, [isOpen, setDrawerVisiblity]);

	useCloseMenuWhenDesktopScreen(isOpen, setIsOpen);

	useDisableScrollWhenMenuOpen(isOpen);

	const popoverMenu = (
		<motion.div
			initial={{ opacity: 0, y: `calc(${HEADER_HEIGHT} + 25px)` }}
			animate={{ opacity: 1, y: HEADER_HEIGHT }}
			exit={{ opacity: 0, y: `calc(${HEADER_HEIGHT} + 25px)` }}
			transition={{
				duration: 0.2,
				ease: [0.455, 0.03, 0.515, 0.955],
				delay: 0.15,
			}}
			className={`${
				colorScheme === 'white' ? 'bg-white' : 'bg-brand-blue'
			} absolute left-0 -top-2 w-full overflow-y-auto pt-16 px-7 pb-48`}
			style={{ height: '100vh' }}
		>
			<div className="md:col-count-2 max-w-5xl">
				{menuItems.map(({ slug, title, categorizedPages }) => (
					<ul key={slug} className="pb-12 max-w-xs" style={{ breakInside: 'avoid-column' }}>
						<li>
							<Link
								to={slug}
								title={title}
								className={`body-medium ${
									colorScheme === 'white' ? 'text-brand-blue' : 'text-white'
								} font-bold inline-block hover:opacity-50`}
								onClick={() => {
									setIsOpen(false);
								}}
							>
								{title}
							</Link>
						</li>
						{categorizedPages?.map(([, pages]) => pages.map((page, index) => (
								<li className="my-3" key={index}>
									<Link
										key={index}
										to={page.slug}
										title={page.title}
										onClick={() => {
											setIsOpen(false);
										}}
										className={`${
											colorScheme === 'white' ? 'text-brand-blue' : 'text-white'
										} body-medium inline-block hover:opacity-50`}
									>
										{page.title}
									</Link>
								</li>
							)))}
					</ul>
				))}
			</div>
		</motion.div>
	);

	return (
		<>
			<BurgerMenuButton
				className="relative"
				isOpen={isOpen}
				strokeColor={
					colorScheme === 'white'
						? tailwindConfig.theme.colors['brand-blue'].DEFAULT
						: tailwindConfig.theme.colors.white.DEFAULT
				}
				onClick={toggleMenu}
			/>
			<AnimatePresence>{isOpen && popoverMenu}</AnimatePresence>
		</>
	);
};
