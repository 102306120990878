import React from 'react';

type DividerProps = {
	className?: string;
	backgroundColor?: string;
	isLight?: string;
};
export const Divider = ({ backgroundColor, className, isLight }: DividerProps) => {
	const dividerStyle = backgroundColor ? { backgroundColor } : { backgroundColor: isLight === '#FFFFFF' ? 'black' : 'white', opacity: isLight ? 0.1 : 0.3 };

	return (
		<div
			className={className}
			style={{
				height: '1px',
				...dividerStyle,
			}}
		/>
	);
};
