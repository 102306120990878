// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import BlockContent from '@sanity/block-content-to-react';
import classNames from 'classnames';
import Img from 'next/image';
import { useRouter } from 'next/router';

import { CanifyLogo } from '@apps/web/components/atoms/canify-logo/CanifyLogo';
import { ROUTES } from '@apps/web/config/routes';
import { FooterType, NavigationType } from '@apps/web/lib/sanity/types';
import { Link } from '@canify/ui-link';

export type FooterProps = {
	className?: string;
	footer: FooterType;
	navigation: NavigationType;
};

interface FooterLinkProps {
	to: string;
	title: string;
}

const FooterLink = ({ to, title }: FooterLinkProps): JSX.Element => (
	<Link className="transition-opacity hover:opacity-50" to={to} title={title}>
		{title}
	</Link>
);

export const Footer = ({ footer, navigation, className }: FooterProps) => {
	const { contact, socialLinks } = footer;
	const { locale } = useRouter();
	const languageLinkActiveClassname = 'text-white border-b-[1px] border-white';
	const languageLinkInActiveClassname = 'text-white text-opacity-50 hover:text-opacity-100 transition-colors';

	const languageSelectElem = (
		<div className="flex">
			<Link
				className={`pb-2 mr-5  ${
					locale === 'en' ? languageLinkActiveClassname : languageLinkInActiveClassname
				}`}
				to={ROUTES.HOME_EN}
				locale="en"
			>
				English
			</Link>
			<Link
				className={`pb-2  ${locale === 'de' ? languageLinkActiveClassname : languageLinkInActiveClassname}`}
				to={ROUTES.HOME_DE}
				locale="de"
			>
				Deutsch
			</Link>
		</div>
	);

	const socialLinksElem = (
		<div>
			<p className="mb-5 body-medium">{socialLinks.title}</p>
			<div className="flex">
				{socialLinks.links.map((link, index) => (
					<a key={index} href={link.url} className="group mr-2">
						<div className="relative flex items-center justify-center w-16 h-16 overflow-hidden rounded-full">
							<div className="absolute top-0 left-0 w-full h-full transition-opacity duration-200 ease-linear bg-white opacity-10 group-hover:opacity-20" />
							<Img
								layout="fixed"
								width={24}
								height={24}
								className="text-white fill-current"
								src={link.icon.asset.url}
							/>
						</div>
					</a>
				))}
			</div>
		</div>
	);

	const navigationElem = (
		<div className="flex flex-col gap-4 font-bold">
			{navigation.navigation?.map(page =>(
				<FooterLink to={page.slug} title={page.title} />
			))}
		</div>
	);

	const secondaryLinksElem = !navigation.secondaryLinks?.length ? null : (
		<div className="flex flex-col gap-4 font-bold">
			{navigation.secondaryLinks?.map((page) => {
				return <FooterLink key={page.slug} to={page.slug} title={page.title} />;
			})}
		</div>
	);

	const hasSecondaryLinks = !!navigation.secondaryLinks?.length;

	return (
		<footer className={`bg-brand-blue pb-16 pt-12 layout-padding-horizontal  ${className}`}>
			<CanifyLogo className="relative w-44" width={200} height={50} fill={'white'} />
			<div
				className={classNames('flex flex-col justify-between text-white md:flex-row', {
					'max-w-xl': !hasSecondaryLinks,
					'max-w-3xl': hasSecondaryLinks,
					'md-lg:max-w-6xl': !hasSecondaryLinks,
					'md-lg:max-w-7xl': hasSecondaryLinks,
				})}
			>
				<div>
					<BlockContent blocks={contact} />
					<div className="mt-16 md:hidden">{navigationElem}</div>
					{hasSecondaryLinks && <div className="mt-16 md:hidden">{secondaryLinksElem}</div>}
					<div className="mt-16 md-lg:hidden">{socialLinksElem}</div>
				</div>
				<div>
					<div className="hidden md:block">{navigationElem}</div>

					<div className="mt-16 md-lg:hidden">{languageSelectElem}</div>
				</div>
				{hasSecondaryLinks && <div className="hidden md:block">{secondaryLinksElem}</div>}
				<div className="hidden md-lg:block">
					{socialLinksElem}
					<div className="mt-10">{languageSelectElem}</div>
				</div>
			</div>
		</footer>
	);
};
