import { Trans } from '@lingui/macro';
import { motion } from 'framer-motion';
import { useRouter } from 'next/router';
import { useState } from 'react';

import { CanifyLogo } from '@apps/web/components/atoms/canify-logo/CanifyLogo';
import { BurgerMenu } from '@apps/web/components/organisms/app-layout/header/BurgerMenu';
import { HeaderMenuItem } from '@apps/web/components/organisms/app-layout/header/HeaderMenuItem';
import {
	useIsHeaderOpague,
	useIsHeaderVisible,
} from '@apps/web/components/organisms/app-layout/header/hooks';
import {
	ColorScheme,
	MenuItemWithCategorizedPages,
} from '@apps/web/components/organisms/app-layout/header/types';
import { ROUTES } from '@apps/web/config/routes';
import { DEFAULT_EASE, HEADER_HEIGHT } from '@apps/web/constants';
import { useAuth } from '@apps/web/contexts/AuthContext';
import { NavigationType } from '@apps/web/lib/sanity/types';
import { AccountService } from '@apps/web/services/AccountService';
import { categorizePages } from '@apps/web/utils/categorisePages';
import { tailwindConfig } from '@canify/theme';
import { Button } from '@canify/ui-button';
import { UserIcon } from '@canify/ui-icon';
import { Link } from '@canify/ui-link';

export type HeaderProps = {
	colorScheme: ColorScheme;
	navigation: NavigationType;
};
export const Header = ({ colorScheme, navigation }: HeaderProps) => {
	const { isHeaderVisible } = useIsHeaderVisible();
	const { isHeaderOpague } = useIsHeaderOpague();
	const [isDrawerVisible, setIsDrawerVisible] = useState(false);
	const { isAuthenticated } = useAuth();
	const router = useRouter();

	const menuItems = [
		...(navigation?.navigation ? navigation.navigation.map((page, index) => ({
			title: page.title,
			slug: page.slug,
			categorizedPages: categorizePages(navigation.navigation[index].pages)
		})) : []),
		navigation.pricing?.slug && {
			title: navigation.pricing.title,
			slug: navigation.pricing.slug,
			categorizedPages: undefined,
		},
		navigation.news?.slug && {
			title: navigation.news.title,
			slug: navigation.news.slug,
			categorizedPages: undefined,
		},
	].filter((item) => !!item) as MenuItemWithCategorizedPages[];

	const navigationLargeScreen = (
		<ul className="hidden header:flex">
			{menuItems?.map((menuItem) => (
				<li
					key={menuItem?.slug}
					className={`${colorScheme === 'white' ? 'text-brand-blue' : 'text-white'} mr-10`}
				>
					<HeaderMenuItem
						menuItem={menuItem}
						underlineColor={
							colorScheme === 'white'
								? tailwindConfig.theme.colors['brand-blue'].DEFAULT
								: tailwindConfig.theme.colors.white.DEFAULT
						}
					/>
				</li>
			))}
		</ul>
	);

	const navigationSmallerScreen = (
		<span className="pr-1 -ml-2 sm:ml-0 md:pr-6 header:hidden">
			<BurgerMenu colorScheme={colorScheme} menuItems={menuItems} setDrawerVisiblity={setIsDrawerVisible} />
		</span>
	);

	return (
		<motion.div
			animate={isHeaderVisible ? 'visible' : 'hidden'}
			variants={{
				visible: {
					translateY: '0%',
				},
				hidden: {
					translateY: '-100%',
				},
			}}
			transition={{ duration: 0.3, ease: DEFAULT_EASE }}
			className="fixed top-0 left-0 z-50 w-full "
		>
			<header className={'pl-5 pr-5 sm:pl-7 sm:pr-7 lg:px-12'} style={{ height: HEADER_HEIGHT }}>
				<div
					className={`absolute w-full h-full left-0 transition-all duration-500 ${
						colorScheme === 'white' ? 'bg-white' : 'bg-brand-blue'
					} ${isHeaderOpague && !isDrawerVisible ? 'bg-opacity-50' : 'bg-opacity-100'}`}
				/>
				<nav className="flex items-center justify-between h-full">
					<div className="flex items-center">
						{navigationSmallerScreen}
						<Link to={ROUTES.HOME}>
							<CanifyLogo
								className="relative block w-24 xs:w-32"
								fill={
									colorScheme === 'white'
										? tailwindConfig.theme.colors['brand-blue'].DEFAULT
										: tailwindConfig.theme.colors.white.DEFAULT
								}
							/>
						</Link>
					</div>
					<div className="flex items-center">
						{navigationLargeScreen}
						<Button
							size="OVERRIDE"
							className="px-4 mr-3 ml-2 button-small md:button-medium header:button-large md:px-10"
							type="filled"
							href={ROUTES.ONBOARDING}
							title="Get started with Canify"
						>
							<Trans id="header.getStartedButton.text">Get started</Trans>
						</Button>
						<Button
							size="OVERRIDE"
							className="button-small md:button-medium header:button-large"
							type="filled"
							onClick={() => {
								!isAuthenticated ? AccountService.signin({ locale: router.locale }) : undefined;
							}}
							href={isAuthenticated ? ROUTES.PATIENT : undefined}
							iconOnly={UserIcon}
							iconProps={{
								isFillColor: false,
							}}
							classNameIcon="icon-small md:icon-medium header:icon-large"
						/>
					</div>
				</nav>
			</header>
		</motion.div>
	);
};
