import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';

import { useIsomorphicLayoutEffect } from '@apps/web/hooks/useIsomorphicLayoutEffect';
import { tailwindConfig } from '@canify/theme';

let lastScroll = 0;
const SCROLL_THRESHOLD = 20;

export function useIsHeaderVisible() {
	const [isHeaderVisible, setIsHeaderVisible] = useState(true);

	const onScroll = () => {
		const currentScroll = window.scrollY;
		// the difference between current and previous scroll decides if the header should be visible
		const scrollDifference = currentScroll - lastScroll;

		if (currentScroll <= 0) {
			setIsHeaderVisible(true);
		}

		if (scrollDifference > SCROLL_THRESHOLD && isHeaderVisible) {
			setIsHeaderVisible(false);
		} else if (scrollDifference < -SCROLL_THRESHOLD && !isHeaderVisible) {
			setIsHeaderVisible(true);
		}

		lastScroll = currentScroll;
	};

	const debouncedOnScroll = () => {
		// debounce because in situations like scrolling to top on mobile, the header might prematurely get triggered to disappear
		debounce(onScroll, 300)();
	};

	useEffect(() => {
		window.addEventListener('scroll', debouncedOnScroll);

		return () => window.removeEventListener('scroll', debouncedOnScroll);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isHeaderVisible]);

	return { isHeaderVisible };
}

export function useIsHeaderOpague() {
	// defaults to true, because on page load we will alway be scrolled to the top
	const [isHeaderOpague, setIsHeaderOpague] = useState(true);

	function onScroll() {
		if (window.scrollY > 0) {
			setIsHeaderOpague(false);
		} else {
			setIsHeaderOpague(true);
		}
	}

	useEffect(() => {
		window.addEventListener('scroll', onScroll, { passive: true });

		return () => {
			window.removeEventListener('scroll', onScroll);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return { isHeaderOpague };
}

export function useCloseMenuWhenDesktopScreen(
	isOpen: boolean,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
	useEffect(() => {
		function closeMenuWhenDesktopScreen() {
			if (window.innerWidth > parseInt(tailwindConfig.theme.screens.lg)) {
				setIsOpen(false);
			}
		}

		if (isOpen) {
			window.addEventListener('resize', closeMenuWhenDesktopScreen);
		} else {
			window.removeEventListener('resize', closeMenuWhenDesktopScreen);
		}

		return () => window.removeEventListener('resize', closeMenuWhenDesktopScreen);
	}, [isOpen, setIsOpen]);
}

export function useDisableScrollWhenMenuOpen(isOpen: boolean) {
	useIsomorphicLayoutEffect(() => {
		if (isOpen) {
			document.body.style.overflowY = 'hidden';
		} else {
			document.body.style.overflowY = 'auto';
		}

		return () => {
			document.body.style.overflowY = 'auto';
		};
	}, [isOpen]);
}

export function useClosePopoverOnScroll(
	isOpen: boolean,
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
) {
	useEffect(() => {
		function closePopover() {
			setIsOpen(false);
		}

		if (isOpen) {
			document.addEventListener('scroll', closePopover);
		} else {
			document.removeEventListener('scroll', closePopover);
		}

		return window.removeEventListener('scroll', closePopover);
	}, [isOpen, setIsOpen]);
}
