import { ReactNode } from 'react';

import { Footer } from '@apps/web/components/organisms/app-layout/footer/Footer';
import { Header, HeaderProps } from '@apps/web/components/organisms/app-layout/header/Header';
import { FooterType, NavigationType } from '@apps/web/lib/sanity/types';

type AppLayoutProps = {
	children: ReactNode;
	colorScheme: HeaderProps['colorScheme'];
	offsetHeader?: boolean;
	className?: string;
	footer: FooterType;
	navigation: NavigationType;
};
export const AppLayout = ({
	children,
	colorScheme,
	offsetHeader = false,
	className,
	footer,
	navigation,
}: AppLayoutProps) => {
	return (
		<>
			<Header navigation={navigation} colorScheme={colorScheme} />
			<main className={`layout-padding-horizontal ${offsetHeader ? 'header-offset' : ''} ${className}`}>
				{children}
			</main>
			<Footer className="mt-auto" navigation={navigation} footer={footer} />
		</>
	);
};
